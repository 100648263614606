<template>
  <div class="row">
    <el-empty v-if="isFalse" :description="errorMessage" class="mx-auto text-center"></el-empty>
    <div class="col-xl-12" v-else>
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <label>Khách hàng</label>
                <el-input type="text" size="medium" v-model="form.name" placeholder="Tên, SĐT" clearable
                          autocomplete="false"></el-input>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <label>Level sale</label>
                <el-select v-model="form.level" filterable clearable class="w-100" placeholder="Chọn level">
                  <el-option
                      v-for="item in levelOption"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <label>Thời gian lên tư vấn</label>
                <el-date-picker
                    class="w-100"
                    v-model="form.selectDate"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="pickStart"
                    :picker-options="pickerStartOptions"
                    start-placeholder="Từ ngày"
                    end-placeholder="Đến ngày">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading.search"
                        class="btn btn-primary w-100 mt-4"
                        @click="find">
                  <i v-bind:class="[loading.search ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <div class="panel-tag mb-3 mt-3">
              <h4>Danh sách khách hàng tư vấn: <b class="text-danger">{{ totalCustomer }}</b></h4>
            </div>
            <el-table
                :data="tableData"
                stripe
                v-loading="loading.table"
                border
                class="table-hover mb-5 table-responsive-lg"
                style="width: 100%">
              <el-table-column label="#" type="index" width="50" :index="indexMethod">
              </el-table-column>
              <el-table-column
                  label="Họ và tên"
              >
                <!--eslint-disable-->
                <template slot-scope="scope">
                  <router-link :to="{name: 'customer-edit', params : {id : scope.row.customer_id}}"
                               style="padding-left: 10px; color: #0dad0d; text-decoration: underline; cursor: pointer;">
                    {{ scope.row.customer.name }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="Số điện thoại">
                <template slot-scope="scope">
                  {{ scope.row.customer.phone }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="level"
                  label="Level">
                <template slot-scope="scope">
                  {{ renderLevel(scope.row.customer.level) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="ngay-hen"
                  label="Thời gian lên tư vấn">
                <template slot-scope="scope">
                  {{ scope.row.created_at| formatDateTime }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Địa điểm hẹn"
                  class="">
                <template slot-scope="scope">
                  {{ scope.row.branch ? scope.row.branch.center.name : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Thời hạn chăm sóc"
              >
                <template slot-scope="scope">
                  {{ renderDayLeft(scope.row) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Hình thức tư vấn"
              >
                <template slot-scope="scope">
                  {{ scope.row.customer ? HINH_THUC_TU_VAN[scope.row.customer.hinh_thuc_tu_van] : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Người giới thiệu"
              >
                <template slot-scope="scope">
                  {{ scope.row.customer && scope.row.customer.user_intro ? scope.row.customer.user_intro.name : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Hành động"
              >
                <template slot-scope="scope" >
                  <div class="d-flex justify-content-center">
                    <div v-if="['3B', '3E'].includes(scope.row.customer.level)">
                      <CustomerDecision :customer="scope.row.customer"></CustomerDecision>
                    </div>
                    <div v-else>
                      <router-link v-if="!['1A', '1C', '2A', '2B', '2C', '2E'].includes(scope.row.customer.level)"
                                   :to="{ name: 'contract-index', query: {phone : scope.row.customer.phone} }"
                                   title="Hợp đồng tư vấn"
                                   class="btn-sm btn btn-success btn-tuvan mr-1 mb-1"><i
                          class="el-icon-s-operation"></i>
                      </router-link>
                    </div>
                    <div>
                      <router-link v-if="scope.row.customer.best_customers && ['4A'].includes(scope.row.customer.level)"
                                   :to="{ name: 'detail-customer', params: {id : scope.row.customer.user_id}, query: {customer_id: scope.row?.customer?.best_customers[0]?.id}}"
                                   title="Xem chi tiết khách hàng"
                                   class="btn-sm btn btn-secondary btn-tuvan mr-1 mb-1">
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="edutalk-paging">
              <div class="block">
                <!--eslint-disable-->
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="paging.per_page"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <contract-form
            @completed="closeDialog"
            v-if="dialogFormVisible"
            :customer-id="contract.customer_id"
            :center-id="contract.center_id"
            :branch-id="contract.branch_id"
            :contract="contract"
            :check-edit="checkEdit"
            :check-view="checkView"
            @close="closeDialog">
        </contract-form>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {accountTypeOption, kiemNghiemOption} from "@/core/config/accountTypeOption";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {OPTION_LEVEL} from "@/core/config/levelOption";
import {mapGetters} from "vuex";
import moment from 'moment-timezone';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {LIST_CUSTOMER_TVV} from "@/core/services/store/advise.module";

const _ = deepdash(lodash);
import ContractForm from "@/views/pages/customer/contract-form";
import {HINH_THUC_TU_VAN} from "@/core/config/customer/customerOption";
import {CENTER_EDUTALK} from "@/core/config/center/centerOption";
import CustomerDecision from "@/views/pages/work/components/CustomerDecision";
import {typeFormatText} from "@/core/config/contract/contractOnlineOption";

export default {
  components: {
    ContractForm,
    CustomerDecision
  },
  name: "khach-hang-tu-van",
  data() {
    return {
      moment: moment,
      HINH_THUC_TU_VAN: HINH_THUC_TU_VAN,
      CENTER_EDUTALK: CENTER_EDUTALK,
      typeFormatText: typeFormatText,
      form: {
        name: null,
        level: null,
        selectDate: []
      },
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      pickerEndOptions: {
        disabledDate: this.disabledEndDate
      },
      loading: {
        table: false,
        search: false,
        save: false,
      },
      tableData: [],
      currentDate: new Date(),
      branches: [],
      paging: {
        current_page: 1,
        per_page: 50,
        total: 0,
      },
      levelOption: OPTION_LEVEL,
      optionAccountType: accountTypeOption,
      kiemNghiemOption: kiemNghiemOption,
      humanResource: [],
      optionMonth: [],
      dialogFormVisible: false,
      dialogFormDismissal: false,
      dialog: {
        title: null,
        isEdit: false,
        isVerify: false,
      },
      isUpdate: false,
      isConfirm: false,
      totalCustomer: 0,
      fromDate: null,
      toDate: null,
      isFalse: false,
      errorMessage: '',
      contract: null,
      checkEdit: false,
      checkView: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tư vấn"},
      {title: "Danh sách khách hàng tư vấn", icon: 'far fa-user'}
    ]);
    let date = new Date();
    // let startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
    // let endDate = this.$moment().format('YYYY-MM-DD');
    // this.form.selectDate = [startDate, endDate]
    this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.toDate = new Date();
    this.form.name = this.$route.query.phone ? this.$route.query.phone : '';
    this.querySever();
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    handleViewContract(contract) {
      this.dialog.title = 'Chi tiết hợp đồng';
      this.dialogFormVisible = true;
      this.checkEdit = false;
      this.checkView = true;
      this.contract = contract;
    },
    handleUpdateContract(contract) {
      this.dialog.title = 'Sửa hợp đồng';
      this.dialogFormVisible = true;
      this.checkEdit = true;
      this.checkView = false;
      this.contract = contract;
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    handleCurrentChange(val) {
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 10 * (this.paging.current_page - 1) + (index + 1);
    },
    coverVerify(item) {
      if (item.profile.status_verify === 1) {
        return 'Xác minh cho nhân sự ngay'
      }
      if (item.profile.status_verify === 2) {
        return 'Đã xác minh danh tính'
      }
      if (item.profile.status_verify === 3) {
        return 'Đã bị từ chối xác minh'
      }
      return ''
    },
    find() {
      this.paging.current_page = 1;
      this.querySever();
    },
    querySever(customProperties) {
      this.loading.table = true;
      this.loading.search = true;
      let params = this.mergeParams(customProperties);
      this.currentPage = 1;
      this.$store.dispatch(LIST_CUSTOMER_TVV, params)
          .then((response) => {
            if (response.status === 422) {
              this.isFalse = true;
              this.errorMessage = response.data.message;
            } else {
              this.tableData = response.data.data;
              this.paging.per_page = parseInt(response.data.per_page);
              this.paging.total = response.data.total;
              this.totalCustomer = response.data.total;
            }
            this.loading.table = false;
            this.loading.search = false;
          })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
      };
      if (this.form.name) {
        params = _.merge(params, {name: this.form.name})
      }
      if (this.form.level) {
        params = _.merge(params, {level: this.form.level})
      }
      if (this.form.selectDate) {
        params = _.merge(params, {selectDate: this.form.selectDate})
      }
      params = _.merge(params, customProperties);
      return params;
    },
    /* date picker methods */
    pickStart(date) {
      this.fromDate = null;
      if (date) {
        this.fromDate = new Date(date);
      }
    },
    pickEnd(date) {
      this.toDate = null;
      if (date) {
        this.toDate = new Date(date);
      }
    },
    disabledStartDate(date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return date > new Date();
    },
    disabledEndDate(date) {
      if (this.fromDate) {
        return this.fromDate > date || date > new Date();
      }
      return date > new Date();
    },
    renderLevel(type) {
      let level = this.levelOption.find((item) => {
        return item.id === type;
      });
      return level ? level.value : '';
    },
    renderDayLeft(logCustomer) {
      let day_left = '';
      if (logCustomer.created_at && ['3B', '3E'].includes(logCustomer.customer.level)) {
        let updateDate = new Date(logCustomer.created_at);
        let expiration_date = updateDate.setDate(updateDate.getDate() + 15);
        let date_current = new Date();
        let diffDate = Math.floor((expiration_date - date_current) / (1000 * 60 * 60 * 24));
        if (diffDate > 0) {
          day_left = "Còn " + diffDate + " ngày";
        }
      }
      return day_left;
    }
  },
}
</script>
