<template>
  <div>
    <button title="Nhập kết quả tư vấn" class="btn-sm btn btn-primary btn-tuvan mr-1 mb-1"
            @click="dialogConsultResult = true">
      <i class="fa fa-edit"></i>
    </button>
    <el-dialog
        class="responsive"
        width="80%"
        title="Nhập kết quả tư vấn"
        :visible.sync="dialogConsultResult"
        append-to-body>
      <div class="row">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form" style="width: 100%">
          <form class="form" autocomplete="off">
            <div class="col-md-12">
              <label>Khách hàng quyết định<span class="text-danger">*</span></label>
              <ValidationProvider vid="customer_consult_id" name="Chọn quyết định"
                                  :rules="{ required: true }"
                                  v-slot="{ errors,classes }">
                <el-select v-model="update.customer_consult_id"
                           placeholder="Chọn quyết định"
                           clearable
                           :class="classes"
                           @input="handleConsult"
                >
                  <el-option
                      v-for="item in CUSTOMER_SELECT_DECISION"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div v-if="update.customer_consult_id === phan_van" class="col-md-12 mt-4">
              <p style="word-break: break-word;" class="mt-4">
                Kể từ lúc khách hàng lên tư vấn, bạn có
                <span
                    class="font-weight-bold">14 ngày</span>
                để chăm
                sóc khách hàng.
                Trong khoảng thời gian này, nếu khách hàng quyết định đăng ký, hãy cập nhật kết quả tư vấn tại
                <span class="font-weight-bold">Danh sách khách hàng tư vấn.</span></p>
            </div>
            <div v-if="update.customer_consult_id === khong_dang_ky" class="col-md-12 mt-4">
              <ValidationProvider vid="reason" name="Lý do"
                                  :rules="{ required : true }"
                                  v-slot="{ errors,classes }">
                <el-input type="textarea"
                          :rows=2
                          :class="classes"
                          placeholder="Nhập lý do khách hàng từ chối"
                          v-model="update.reason"
                >
                </el-input>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-12 mt-4" v-if="update.customer_consult_id === dang_ky">
              <label>Sản phẩm đăng ký<span class="text-danger">*</span></label>
              <ValidationProvider vid="product_type_id" name="Sản phẩm đăng ký"
                                  :rules="{ required : true }"
                                  v-slot="{ errors,classes }">
                <el-select v-model="update.product_type_id"
                           placeholder="Chọn sản phẩm đăng ký"
                           clearable
                           :class="classes"
                >
                  <el-option
                      v-for="item in PRODUCT_CUSTOMER_SELECT"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-12 mt-4" v-if="update.customer_consult_id === dang_ky">
              <p style="word-break: break-word;" class="mt-4">
                Bạn đã nhập kết quả tư vấn là <span
                  class="font-weight-bold">Khách hàng đăng ký</span>. Tiếp theo, bạn cần tạo <span
                  class="font-weight-bold">Phiếu đăng ký học tập cho
                khách hàng.</span></p>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogConsultResult = false">Hủy bỏ</el-button>
        <el-button type="primary" :loading="loading" @click="consultResultCustomer">Xác nhận</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  CUSTOMER_SELECT_DECISION,
  DANG_KY,
  KHONG_DANG_KY,
  PHAN_VAN,
  PRODUCT_CUSTOMER_SELECT, SAN_PHAM_EDUTALK, SAN_PHAM_NHAN_DOI_TAC
} from "@/core/config/customer/customerOption";
import {CUSTOMER_CONSULT_RESULT} from "@/core/services/store/customer/customer.module";

export default {
  name: "CustomerDecision",
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      loading: false,
      dialogConsultResult: false,
      update: {
        customer_consult_id: this.customer.customer_consult_id ? parseInt(this.customer.customer_consult_id) : '',
        product_type_id: this.customer.product_type_id ? parseInt(this.customer.product_type_id) : '',
        reason: this.customer.reason,
        id: this.customer.id,
        level: this.customer.level ? this.customer.level : '3B',
        customer_id:this.customer.id ? parseInt(this.customer.id) : ''
      },
      CUSTOMER_SELECT_DECISION: CUSTOMER_SELECT_DECISION,
      PRODUCT_CUSTOMER_SELECT: PRODUCT_CUSTOMER_SELECT,
      khong_dang_ky: KHONG_DANG_KY,
      dang_ky: DANG_KY,
      phan_van: PHAN_VAN,
      SAN_PHAM_EDUTALK: SAN_PHAM_EDUTALK,
      SAN_PHAM_NHAN_DOI_TAC: SAN_PHAM_NHAN_DOI_TAC,
    };
  },
  methods: {
    consultResultCustomer() {
      if (this.update.customer_consult_id === KHONG_DANG_KY) {
        this.update.level = '1A';
      }
      this.register();
    },
    register() {
      this.loading = true;
      console.log("this.$refs.form",this.$refs.form);
      this.$store.dispatch(CUSTOMER_CONSULT_RESULT, this.update).then((data) => {
        this.$message.success(data.message);
        this.dialogConsultResult = false;
        this.redirect();
      }).catch((e) => {
        if (e.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    redirect() {
      switch (this.update.customer_consult_id) {
        case this.phan_van :
          this.$router.push({
            name: "list-customer-tvv",
            params: {
              customer_id: this.customer.id
            }
          })
          break;
        case this.khong_dang_ky:
          this.$router.push(
              {
                name: "list-customer-tvv",
                params: {
                  customer_id: this.customer.id
                }
              }
          )
          break;
        case this.dang_ky:
          if (this.update.product_type_id === SAN_PHAM_EDUTALK) {
            this.$router.push({
              name: "register-study",
              query: {
                customer_id: this.customer.id
              }
            })
          } else {
            this.$router.push({
              name: "register-study-partner",
              query: {
                customer_id: this.customer.id
              }
            })
          }
          break;
      }
    },
    handleConsult() {
      switch (this.update.customer_consult_id) {
        case this.phan_van :
          this.update.level = '3E';
          break;
        case this.khong_dang_ky:
          this.update.level = '3C';
          break;
        case this.dang_ky:
          this.update.level = '';
          break;
      }
    }
  }
}
</script>

<style scoped>
.el-select {
  display: inline-block;
  position: relative;
  width: 100%;
}

</style>
<style>
.el-checkbox__inner:hover, .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #472F92 ;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #472F92 ;
  background-color: #472F92;
}
.el-checkbox__input.is-checked+.el-checkbox__label, .el-checkbox__input.is-checked+.el-checkbox__label {
color: #472F92;
}
</style>