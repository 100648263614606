export const OPTION_LEVEL = [
    {id: '1A', value: '1A - Nhu cầu'},
    {id: '1C', value: '1C - Trống'},
    {id: '2A', value: '2A - Có lịch hẹn'},
    {id: '2B', value: '2B - Delay hẹn'},
    {id: '2C', value: '2C - Hủy hẹn'},
    {id: '2E', value: '2E - Đặt lịch đóng tiền'},
    {id: '3', value: '3 - Tư vấn'},
    {id: '3A', value: '3A - Đăng ký'},
    {id: '3B', value: '3B - Khách hàng đến'},
    {id: '3C', value: '3C - Không đăng ký'},
    {id: '3E', value: '3E - Phân vân'},
    {id: '4A', value: '4A - Đã đóng trên 1200k'},
    {id: '4B', value: '4B - Đóng tiền dưới 1200k'},
    {id: '4D', value: '4D - Rút tiền'},
    {id: '5A', value: '5A - Đã đăng ký khóa combo'},
    {id: '5B', value: '5B - Đã đăng ký khóa lẻ'},
    {id: '5D', value: '5D - Hủy đăng ký'},
    {id: '6', value: '6 - Đang học'},
    {id: '7', value: '7 - Đợi lớp'},
    {id: '8', value: '8 - Ngừng học'},
];